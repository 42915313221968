// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes notificationEnter {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes notificationExit {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

.notification {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  z-index: 50;
  color: white;
}

.notification.warning {
  background-color: #eab308;
}

.notification.error {
  background-color: #ef4444;
}

.notification-enter {
  animation: notificationEnter 0.3s ease-out forwards;
}

.notification-exit {
  animation: notificationExit 0.3s ease-out forwards;
}`, "",{"version":3,"sources":["webpack://./src/component/Service_page/Notification.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;IACV,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,2BAA2B;EAC7B;AACF;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,aAAa;EACb,qBAAqB;EACrB,+CAA+C;EAC/C,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,kDAAkD;AACpD","sourcesContent":["@keyframes notificationEnter {\n  from {\n    opacity: 0;\n    transform: translateY(20px);\n  }\n  to {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n\n@keyframes notificationExit {\n  from {\n    opacity: 1;\n    transform: translateY(0);\n  }\n  to {\n    opacity: 0;\n    transform: translateY(20px);\n  }\n}\n\n.notification {\n  position: fixed;\n  bottom: 1rem;\n  right: 1rem;\n  padding: 1rem;\n  border-radius: 0.5rem;\n  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);\n  z-index: 50;\n  color: white;\n}\n\n.notification.warning {\n  background-color: #eab308;\n}\n\n.notification.error {\n  background-color: #ef4444;\n}\n\n.notification-enter {\n  animation: notificationEnter 0.3s ease-out forwards;\n}\n\n.notification-exit {\n  animation: notificationExit 0.3s ease-out forwards;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
